import { CenteredContentBlocks } from "@components/article/CenteredContentBlocks"
import { FloatingBackButton } from "@components/atoms/FloatingBackButton"
import { StaggerTransitionRoot } from "@components/motion/Transition"
import { MembersOnlyContent } from "@features/accounts/components/MembersOnlyContent"
import { ContentBlock } from "eddev/blocks"
import { defineView } from "eddev/views"
import { useMemo } from "react"

export default defineView("single-article", (props) => {
  const blocks = useMemo(() => {
    return props.article?.contentBlocks.flatMap((block) => {
      if (block.blockName === "acf/article-article-siderail") {
        return block.innerBlocks
      } else {
        return block
      }
    }) as ContentBlock[]
  }, [props.article?.contentBlocks])

  return (
    <>
      <MembersOnlyContent enforce={props.article?.membersOnly}>
        <StaggerTransitionRoot>
          <CenteredContentBlocks blocks={blocks} />
        </StaggerTransitionRoot>
        <FloatingBackButton />
      </MembersOnlyContent>
    </>
  )
})
